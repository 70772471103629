import Base from './base'
import Dispatcher from './dispatcher'
import { fetchJSONP } from '../utils'

const DEFAULT_API_KEY = 'C5VPNN3HH87ZR8M33TBQ'
const GEMINI_TOTAL_ADS = 10
const UPSTREAM = 'https://ads.yap.yahoo.com/nosdk/wj/v1/getAds.do'

export default (() =>
  class Gemini extends Base {
    constructor(elem, options) {
      super(elem, options)
      this.name = 'Gemini'
      this.fallbackClass = Dispatcher
    }

    // These store refs to the gemini ads being used on the window object to allow dedupping
    get currentGeminiAds() {
      return window.AOL?.ads?.gemini || []
    }

    set currentGeminiAds(adId) {
      window.AOL = window.AOL || {}
      window.AOL.ads = window.AOL.ads || []
      window.AOL.ads.gemini = window.AOL.ads.gemini || []
      window.AOL.ads.gemini.push(adId)
    }

    async _fetchUpstreamData() {
      const { apiKey = DEFAULT_API_KEY, adUnitCode, views: adCountBeacons = [] } = this.config
      const requestQuery = {
        local: 'en_us',
        agentVersion: '205',
        adTrackingEnabled: true,
        totalAds: GEMINI_TOTAL_ADS,
        publisherUrl: encodeURIComponent(window.location.href),
        apiKey,
        adUnitCode
      }

      return fetchJSONP(UPSTREAM, requestQuery)
        .then(response => response.json())
        .then(data => {
          if (!data || data.error) {
            throw new Error(data.error)
          }

          const { ads } = data
          if (!ads || ads.length <= 0) {
            throw new Error('No Gemini Ads Returned')
          }

          const unseenAds = ads.filter(
            ad => !this.currentGeminiAds.includes(ad.demandSourceCreativeId)
          )
          const adsAvailable = unseenAds.length > 0 ? unseenAds : ads

          const rand = Math.floor(Math.random() * adsAvailable.length)
          const geminiAdData = adsAvailable[rand] || adsAvailable[0]

          // TODO: currently the below is not used, but was copied from JAC incase we need to add something similar with GAM
          // ensure adcount beacon from JAC is included for impression tracking
          geminiAdData.tag = geminiAdData.tag || {}
          geminiAdData.tag.imprTrackingUrl = geminiAdData.tag.imprTrackingUrl || []
          geminiAdData.tag.imprTrackingUrl = geminiAdData.tag.imprTrackingUrl.concat(adCountBeacons)

          geminiAdData.placementType = 'gemini:item'

          this.currentGeminiAds = geminiAdData.demandSourceCreativeId
          return geminiAdData
        })
    }

    _setActiveClasses() {
      this.elem.parentNode?.setAttribute('data-type', 'gemini:placeholder')
      this.elem.parentNode?.classList.add('dl__slide--ad')
      this.elem.classList.add('dl-gemini')
    }

    _unsetActiveClasses() {
      this.elem.parentNode?.classList.remove('dl__slide--ad')
      this.elem.classList.remove('dl-gemini')
    }
  })()
