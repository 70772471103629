import Base from './base'

// This path is relative as the route is set up in ATS, for dev we have a dev proxy route set up
const UPSTREAM = '/dispatcher/channels/aolHomepageJson'
const FALLBACK_OFFERS = [
  {
    bgImage: {
      alt: '',
      credit: 'Getty',
      oneXUrl: 'https://s.yimg.com/cv/apiv2/aolfp/images/dl/dispatcher_fallback_afe.jpg'
    },
    contentType: 'subscription',
    context: 'dlslot',
    descriptionText:
      'You can boost your email productivity by removing distracting paids ads from your inbox -- available on AOL webmail.',
    headingText: 'How to remove paid ads from your email inbox',
    impressionUrl: '',
    link: {
      actionsUrl: '',
      targetNewTab: 'Y',
      text: 'Ad-free email',
      url: 'https://www.aol.com/products/utilities/ad-free-mail?ncid=mbr_rusacqdsp00000639'
    },
    ncid: 'mbr_rusacqdsp00000639',
    source: {
      labelText: '',
      logoUrl: '',
      text: 'AOL Premium',
      url: 'https://subscriptions.aol.com'
    }
  }
]
const TIMEOUT_VALUE = 2000 // 2 seconds

const inMemoryCache = {}

const memoize = async (fn, key) => {
  if (inMemoryCache[key]) {
    return inMemoryCache[key]
  }
  const result = await fn()
  inMemoryCache[key] = result
  return result
}

export default (() =>
  class Dispatcher extends Base {
    constructor(elem, options) {
      super(elem, options)
      this.name = 'Dispatcher'
    }

    get currentDispatcherAds() {
      return window.AOL?.ads?.dispatcher || []
    }

    set currentDispatcherAds(adId) {
      window.AOL = window.AOL || {}
      window.AOL.ads = window.AOL.ads || []
      window.AOL.ads.dispatcher = window.AOL.ads.dispatcher || []
      window.AOL.ads.dispatcher.push(adId)
    }

    async _fetchUpstreamData() {
      const offers = await memoize(this._fetchDispatcherData.bind(this), this.config.context)
      const unseenAd = offers.find(ad => !this.currentDispatcherAds.includes(ad.ncid))
      const adToRender = unseenAd || offers[0]
      this.currentDispatcherAds = adToRender.ncid
      return adToRender
    }

    async _fetchDispatcherData() {
      const { context, cobrand } = this.config

      const params = {
        contexts: context,
        ...(cobrand ? { cobrand } : {})
      }

      const query = Object.keys(params)
        .map(key => `${key}=${encodeURIComponent(params[key])}`)
        .join('&')

      const url = `${UPSTREAM}?${query}`

      let id
      const fetchPromise = fetch(url)
        .then(response => response.json())
        .then(data => {
          clearTimeout(id)
          return data?.nameToContext?.[context]?.offers
        })
        .catch(e => {
          this._logError(`Using Fallback - ${e.message || 'request failed'}`)
          return FALLBACK_OFFERS
        })

      const timeoutPromise = new Promise(resolve => {
        id = setTimeout(() => {
          clearTimeout(id)
          this._logError('Using Fallback - Request Timeout')
          resolve(FALLBACK_OFFERS)
        }, TIMEOUT_VALUE)
      })

      let offers = await Promise.race([fetchPromise, timeoutPromise])
      if (!offers) {
        offers = FALLBACK_OFFERS
        this._logError('Using Fallback - No Offer Returned')
      }

      return offers.map(offer => ({
        ...offer,
        placementType: 'dispatcher:item'
      }))
    }

    _setActiveClasses() {
      this.elem.parentNode?.setAttribute('data-type', 'dispatcher:placeholder')
      this.elem.classList.add('dl-dispatcher')
    }

    _unsetActiveClasses() {
      this.elem.classList.remove('dl-dispatcher')
    }
  })()
