import { Dispatcher, Gemini, Benji, Taboola } from '../../../../lib/ads/placements'
import { dispatchCustomEvent } from '../../../../lib/utils/events'
import { Constants, errorLogger } from '../../../../lib/ads/utils'

const CLASS_DL_DISPATCHER = 'dl-dispatcher'
const CLASS_DL_GEMINI = 'dl-gemini'
const CLASS_DL_BENJI = 'dl-benji'
const CLASS_DL_TABOOLA = 'dl-taboola'
const CLASS_DL_PLACEHOLDER = 'dl__slide-placeholder'
const SELECTOR_DL_AD_WF_MOD = `.${CLASS_DL_PLACEHOLDER}.wafer-module-complete`
const AD_REFRESH_LIMIT = 3
const ADS_TO_REFRESH = ['rr-atf']
const REFRESHABLE_DL_INDEXES = [9, 24, 39]

export default (() => {
  class DL {
    constructor({ selector }) {
      this.elem = document.querySelector(selector)
      if (!this.elem) {
        return
      }
      this.initializedPlacements = new Map()
      this.adRefreshCounter = 0

      this.waferModuleAddedQueue = []
      window.wafer.ready(() => {
        // This line is to catch any wafer-module instances that may have gotten added prior to these listeners getting added
        this.waferModuleAddedQueue = this.waferModuleAddedQueue.concat([
          ...document.querySelectorAll(SELECTOR_DL_AD_WF_MOD)
        ])
        this.addWaferEvents()
        this.processQueue()
      })
    }

    /**
     * Process the queue of dl placeholders waiting to be inited
     */
    processQueue() {
      while (this.waferModuleAddedQueue.length > 0) {
        const elem = this.waferModuleAddedQueue.shift()
        this.handleWaferModuleAdded({ elem })
      }
    }

    /**
     * addWaferEvents
     * Adds wafer specific event listeners to the page,
     * added it to its own method to make it easier to strip out in the future
     */
    addWaferEvents() {
      window.wafer.on('module:added', event => {
        this.handleWaferModuleAdded(event)
      })
      window.wafer.on('dl:threadmilled', event => {
        this.handleDlThreadmill(event)
      })

      window.wafer.on('dl:change', event => {
        this.handleDlChange(event)
      })
    }

    /**
     * handleWaferModuleAdded
     * Handles the addition of a wafer module to the page
     * @param {Object} elem - Object with an 'elem' property, which is the DOM Element of the moduel added
     */
    handleWaferModuleAdded(data = {}) {
      const { elem = {} } = data
      const slide = elem.parentElement?.classList.contains('dl__slide') ? elem.parentElement : elem
      if (!elem.classList.contains(CLASS_DL_PLACEHOLDER) || this.initializedPlacements.has(slide)) {
        return
      }

      const placementOptions = {
        moduleLogLbl: 'DL'
      }
      let placement
      if (elem.classList.contains(CLASS_DL_DISPATCHER)) {
        placement = new Dispatcher(elem, placementOptions)
      } else if (elem.classList.contains(CLASS_DL_GEMINI)) {
        placement = new Gemini(elem, placementOptions)
      } else if (elem.classList.contains(CLASS_DL_BENJI)) {
        placement = new Benji(elem, placementOptions)
      } else if (elem.classList.contains(CLASS_DL_TABOOLA)) {
        placement = new Taboola(elem, placementOptions)
      }

      if (!placement) {
        this._logError('Unsupported Placement', elem.classList)
        return
      }

      this.initializedPlacements.set(slide, placement)

      const deferRendering =
        (placement instanceof Benji || placement instanceof Taboola) &&
        !(slide.classList.contains('active') || slide.classList.contains('next-slide'))

      if (!deferRendering) {
        placement.renderNatively().catch(e => {
          this.initializedPlacements.delete(slide)
          dispatchCustomEvent('ad:initerror', {})
          this._logError(e)
        })
      }
    }

    /**
     * Refreshed the leaderboard and rr ads when the dl changes
     * @param {Object} event - event obect returnd by wafer emit event
     */
    handleDlChange(event) {
      const { elem, meta: { index = false } = {} } = event
      if (!elem.classList.contains('dl') || !index) {
        return
      }

      const currentSlide = elem.querySelector('.dl__slide.active')
      const adPlacement = this.initializedPlacements.get(currentSlide)
      if (adPlacement) {
        adPlacement.onIsCurrentSlide()
      }

      const nextSlide = elem.querySelector('.dl__slide.next-slide')
      const nextAdPlacement = this.initializedPlacements.get(nextSlide)
      if (nextAdPlacement) {
        nextAdPlacement.onIsNextSlide()
      }

      const { wfAdRefreshLimit, wfAdRefreshIndexes, wfAdRefreshIds } = elem.dataset || {}
      let refreshLimit = AD_REFRESH_LIMIT
      let refreshIndexes = REFRESHABLE_DL_INDEXES
      let refreshAds = ADS_TO_REFRESH
      if (wfAdRefreshLimit) {
        const configLimit = parseInt(wfAdRefreshLimit, 10)
        /* eslint-disable no-restricted-properties */
        if (window.isNaN && !window.isNaN(configLimit)) {
          refreshLimit = configLimit
        }
      }
      if (wfAdRefreshIndexes) {
        try {
          refreshIndexes = JSON.parse(wfAdRefreshIndexes)
        } catch (e) {
          // do nothing so default value gets used
        }
      }
      if (wfAdRefreshIds) {
        try {
          refreshAds = JSON.parse(wfAdRefreshIds)
        } catch (e) {
          // do nothing so default value gets used
        }
      }

      if (
        !refreshAds.length ||
        this.adRefreshCounter >= refreshLimit ||
        !refreshIndexes.includes(index)
      ) {
        return
      }

      this.adRefreshCounter += 1

      dispatchCustomEvent('GAM:refresh', refreshAds)
    }

    _logError(e, level = Constants.ERROR_LEVEL.ERROR) {
      const error = typeof e === 'string' ? new Error(e) : e
      const logger = errorLogger()
      logger(`[DL ${level}] (controller) ${error?.message || 'Generic Error'}`, error)
    }

    handleDlThreadmill(event) {
      const { elem, meta: { added, removed } = {} } = event
      if (!elem.classList.contains('dl')) {
        return
      }
      const addedPlacement = this.initializedPlacements.get(added)
      if (addedPlacement) {
        addedPlacement.attachedToDOM()
      }

      const removedPlacement = this.initializedPlacements.get(removed)
      if (removedPlacement) {
        removedPlacement.removedFromDOM()
      }
    }
  }

  return DL
})()
