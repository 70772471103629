import { Constants, errorLogger } from '../utils'

export default (() =>
  class Base {
    constructor(elem, options = {}) {
      this.options = options
      this.name = 'Base'
      this.elem = elem
      if (!this.elem) {
        return
      }

      try {
        this.config = JSON.parse(this.elem.dataset.config)
      } catch (e) {
        console.error('Error parsing config for', elem)
      }
    }

    // PUBLIC FUNCTIONS
    async renderNatively() {
      try {
        const data = await this._fetchUpstreamData()
        const markup = await this._fetchMarkup(data)
        this._injectMarkup(markup)
      } catch (e) {
        if (this.config.fallback) {
          this._logError(`Using Fallback - ${e.message}`, Constants.ERROR_LEVEL.WARN)
          this.renderFallback()
        } else {
          throw e
        }
      }
    }

    async renderFallback() {
      if (this.config.fallback && this.fallbackClass) {
        const FallbackClass = this.fallbackClass
        this.fallbackPlacement = new FallbackClass(this.elem)
        this.fallbackPlacement.setConfig({ ...this.config.fallback })
        this.fallbackPlacement.renderNatively().then(() => {
          this._unsetActiveClasses()
          this.fallbackPlacement._setActiveClasses()
          this.fallbackRendered = true
        })
      }
    }

    async attachedToDOM() {
      return Promise.resolve()
    }

    async removedFromDOM() {
      return Promise.resolve()
    }

    async onIsCurrentSlide() {
      return Promise.resolve()
    }

    async onIsNextSlide() {
      return Promise.resolve()
    }

    setConfig(config = {}) {
      this.config = config
    }

    // PRIVATE FUNCTIONS

    // This method should be overridden by the class extending this class
    async _fetchUpstreamData() {
      return {}
    }

    async _fetchMarkup(data = {}) {
      const { renderPath, index = 0, totalSlides } = this.elem.dataset
      const body = {
        data: [{ ...data, index, totalSlides }]
      }
      const fetchOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }
      return fetch(renderPath, fetchOptions).then(response => response.json())
    }

    _injectMarkup({ html }) {
      try {
        const tempElem = document.createElement('div')

        // eslint-disable-next-line no-unsanitized/property
        tempElem.innerHTML = html // nosemgrep
        const selectorElem = tempElem.querySelector('.slides .dl__slide')

        // eslint-disable-next-line
        tempElem.innerHTML = (selectorElem && selectorElem.innerHTML) || html // nosemgrep
        if (this.elem.nextSibling) {
          this.elem.parentNode.removeChild(this.elem.nextSibling)
        }
        this.elem.insertAdjacentElement('beforeend', tempElem)
        if (window.wafer) {
          setTimeout(() => {
            // Although this is not a UAC ad, wafer-dl listens for the uac:adinjected event here:
            // - https://git.vzbuilders.com/web-core/wafer-mono/blob/ee84ffa558603aca09d93ade8303c8f25c554017/packages/wafer-dl/src/plugins/staticcaption.js#L60
            // - https://git.vzbuilders.com/web-core/wafer-mono/blob/70eab6983e4e4c38dc79c1e83e0c19c2e07c86ed/packages/wafer-dl/src/plugins/tracking.js#L53
            window.wafer.base.emitWaferEvent('uac:adinjected', {
              elem: this.elem.parentNode,
              ad: this.elem,
              adMarkup: tempElem
            })
            window.wafer.base.sync(this.elem.parentNode)
          }, 10)
        }
      } catch (e) {
        this._logError(e)
      }
    }

    _logError(e, level = Constants.ERROR_LEVEL.ERROR) {
      const error = typeof e === 'string' ? new Error(e) : e
      const logger = errorLogger()
      logger(
        `[${this.options.moduleLogLbl || 'DL'} ${level}] (${this.name}) ${error?.message ||
          'Generic Error'}`,
        error
      )
    }

    _setActiveClasses() {}

    _unsetActiveClasses() {}
  })()
