import Base from './base'
import Gemini from './gemini'
import Dispatcher from './dispatcher'

const TIMEOUT_VALUE = 2000 // 2 seconds

export default (() =>
  class Benji extends Base {
    constructor(elem, options) {
      super(elem, options)
      this.name = 'Benji'
      this.initialized = false
      this.fallbackRendered = false
    }

    get isRendered() {
      return this.benjiPlacement?.isRendered || this.fallbackRendered
    }

    async renderNatively() {
      if (!this.elem.isConnected || this.isRendered || this.initialized) return
      this.benjiPlacement = await this._getPlacement()
      this.benjiPlacement.setOnEmptyCallback(this._renderFallback.bind(this))
      this.benjiPlacement.create()
      this.initialized = true
    }

    async attachedToDOM() {
      if (this.fallbackRendered || !this.initialized) return Promise.resolve()
      return this.benjiPlacement?.refresh()
    }

    async onIsCurrentSlide() {
      return this._render()
    }

    async onIsNextSlide() {
      return this._render()
    }

    async _render() {
      if (this.isRendered || this.benjiPlacement?.isPending) return Promise.resolve()
      return this.renderNatively().catch(e => this._renderFallback())
    }

    async _getPlacement() {
      return new Promise(resolve => {
        if (window.GAM?.createPlacement) {
          const placement = window.GAM?.createPlacement(this.elem)
          return resolve(placement)
        }

        const createPlacementPromise = new Promise((pass, err) => {
          document.addEventListener('GAM:inited', () => {
            if (window.GAM?.createPlacement) {
              const placement = window.GAM?.createPlacement(this.elem)
              return pass(placement)
            }
            return err(new Error('[GAM] interface error'))
          })
        })

        let id
        const timeoutPromise = new Promise((_, err) => {
          id = setTimeout(() => {
            clearTimeout(id)
            return err(new Error('[GAM] timeout'))
          }, TIMEOUT_VALUE)
        })

        return resolve(Promise.race([createPlacementPromise, timeoutPromise]))
      })
    }

    _renderFallback() {
      if (!this.config.fallback || this.isRendered || this.benjiPlacement?.isPending) return
      const FallbackClass = this.config.fallback.type === 'dispatcher' ? Dispatcher : Gemini
      this.fallbackPlacement = new FallbackClass(this.elem)
      this.fallbackPlacement.setConfig({ ...this.config.fallback })
      this.fallbackPlacement.renderNatively().then(this._setFallbackActive.bind(this))
      this.benjiPlacement?.setFallbackRenderedStatus()
      this.fallbackRendered = true
    }

    _setFallbackActive() {
      if (this.benjiPlacement?.isRendered || this.benjiPlacement?.isPending) return
      this._unsetActiveClasses()
      this.fallbackPlacement._setActiveClasses()
    }

    _unsetActiveClasses() {
      this.elem.classList.remove('dl-benji')
      this.elem.style.display = 'unset'
    }

    _setActiveClasses() {
      this.elem.parentNode?.setAttribute('data-type', 'benji:placeholder')
      this.elem.parentNode?.classList.add('dl__slide--ad')
      this.elem.classList.add('dl-benji')
    }
  })()
